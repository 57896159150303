import { useCallback, useContext } from 'react'
import { ContextShell } from '../../../../../../packages/web-app-shell/src'

interface EventParams {
  name: string
  userId?: string
  category: string
  label?: string
  fbEventId?: string
  customAttributes?: {
    [key: string]: string | string[] | number | boolean | undefined
  }
}

interface UseEvent {
  sendEvent(event: EventParams): any
}

export const useEvent = (): UseEvent => {
  const {
    user,
    event: { sendEvent: handleSendEventByShell }
  } = useContext(ContextShell)

  const sendEvent = useCallback(
    (event: EventParams) => {
      handleSendEventByShell({ userId: user?.id, ...event })
    },
    [user?.id, handleSendEventByShell]
  )

  return {
    sendEvent
  }
}
