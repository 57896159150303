import React, { useContext, useEffect, useState } from 'react'
import { useEffectOnce } from 'react-use'
import { I18nContainer } from '../../containers/i18n-container'
import { ToastContainer } from '../../containers/toast-container'
import { ContextShell } from '../context'
import { ShellProps } from '../../types/shell.types'
import { Sidebar } from '../sidebar/sidebar'
import { Loading } from '../../components/loading'
import { ModalKeyboardShortcuts } from '../modals/modal-keyboard-shortcuts'
import { DownloadQueueContainer } from '../../containers/download-queue-container'
import { ModalGetApp } from '../modals/modal-get-app'
import { useUserLogged } from '../../hooks/auth/use-user-logged'
import styles from './styles.module.scss'
import { ModalReferralLink } from '../modals/modal-referral-link'
import { getEnv } from '../../config'
import { ModalGroupPlanTrial } from '../modals/modal-group-plan-promotion'

export const ShellWrapper: React.FC<ShellProps> = ({ children }) => {
  const { user } = useContext(ContextShell)
  const [loaded, setLoaded] = useState(false)

  useUserLogged()

  useEffect(() => {
    if (user && !loaded) {
      setLoaded(true)
    }
  }, [user, loaded])

  useEffectOnce(() => {
    // eslint-disable-next-line no-console
    console.log('Shell v.1.1 env', getEnv())
  })

  return (
    <div id="shell-app">
      <ToastContainer />

      <div className={styles.container}>
        <div className={styles.sidebar}>
          <I18nContainer>
            <>
              <ModalGetApp />
              <ModalReferralLink />
              <ModalGroupPlanTrial />
              <ModalKeyboardShortcuts />
              <DownloadQueueContainer />

              <Sidebar loading={!user} />
            </>
          </I18nContainer>
        </div>

        <div className={styles.content}>
          {loaded ? children : <Loading className={styles.loading} />}
        </div>
      </div>
    </div>
  )
}
