import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { AppFrame } from 'scala'
import { useRouter } from 'next/router'
import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { trigger } from '../../lib/events'
import { useDesktopApp } from '../../../../../packages/web-app-shell/src/hooks/misc/use-desktop-app'
import { ContextShell } from '../../../../../packages/web-app-shell/src'

interface AuthenticatedContainerType {
  children: ReactElement
}

export const AppFrameWrapper: React.FC<AuthenticatedContainerType> = ({
  children
}) => {
  const { i18n } = useLingui()
  const { pathname, push } = useRouter()
  const { userToken, user } = useContext(ContextShell)
  const { isDesktopApp } = useDesktopApp()

  const [campaignGlobalVerified, setCampaignGlobalVerified] = useState(false)
  const isDenoiserEnabled = useMemo(
    () => user?.featureFlags?.denoiser || false,
    [user]
  )

  useEffect(() => {
    if (userToken && !campaignGlobalVerified) {
      setCampaignGlobalVerified(true)
    }
  }, [userToken, campaignGlobalVerified])

  const strings = useMemo(() => {
    const extraLabels = !isDesktopApp
      ? { 'actions.downloadDesktopApp': i18n._(t`get_desktop_app`) }
      : {}

    return {
      'label.beta': i18n._(t`beta`),
      'header.state.loadingFailed': i18n._(t`task.list.state.failed`),
      'account.labelPremium': i18n._(t`label.premium`),
      'label.support': i18n._(t`label.support`),
      unlock_all_features: i18n._(t`unlock_all_features`),
      manageSubscription: i18n._(t`manageSubscription`),
      'label.shortcuts': i18n._(t`keyboard_shortcuts`),
      'account.settings': i18n._(t`header.nav.user.settings`),
      'account.premium': i18n._(t`header.nav.user.getpremium`),
      'account.signOut': i18n._(t`header.nav.user.logout`),
      'actions.getapp': i18n._(t`actions.getapp`),
      notification_settings_title: i18n._(t`notification_settings_title`),
      'modal.mobile.title': `${i18n._(t`modal.mobile.title`)}?`,
      ...extraLabels
    }
  }, [i18n, isDesktopApp])

  const handleNavigation = useCallback(
    (path: string) => {
      switch (path) {
        case '/support':
          window.open(path, '_blank', 'noopener')
          break
        case '/logout':
          trigger('purge:tasks')
          push(path)
          break
        default:
          push(path)
          break
      }
    },
    [push]
  )

  const navItems = useMemo(
    () =>
      [
        {
          label: i18n._(t`task.label.separate`),
          id: 'library_tab_button',
          link: '/library/',
          active: pathname === '/library'
        },
        {
          label: 'Voice Studio',
          id: 'voice_tab_button',
          link: '/voice-studio/',
          active: pathname === '/voice-studio'
        },
        {
          label: i18n._(t`task.label.master`),
          id: 'mastering_tab_button',
          link: '/mastering/',
          active: pathname === '/mastering'
        },
        isDenoiserEnabled && {
          label: i18n._(t`task.label.denoiser`),
          id: 'denoiser_tab_button',
          link: '/library/?filter=denoiser',
          active: pathname === '/denoiser'
        },
        {
          label: i18n._(t`lyric_writer`),
          id: 'lyric_writer_tab_button',
          link: '/lyric-writer/',
          active: pathname === '/lyric-writer'
        },
        {
          label: 'Plugins',
          id: 'plugins_tab_button',
          link: '/plugins/',
          active: pathname === '/plugins'
        }
      ].filter((item) => !!item),
    [i18n, pathname, isDenoiserEnabled]
  )

  return (
    <AppFrame
      strings={strings}
      navItems={navItems}
      onNavigate={handleNavigation}
      currentPathName={pathname}
    >
      {children}
    </AppFrame>
  )
}
