import { useMemo } from 'react'
import { Icon } from '../../../components/icon'
import { Tooltip } from '../../../components/tooltip'
import { FileSize } from './file-size'

interface FileInfoProps {
  name: string
  status?: boolean
  type: string
  size: number
  typeError?: {
    tooLarge?: boolean
    typeNotSupported?: boolean
    generalError?: boolean
    connectionError?: boolean
  }
  loading?: boolean
  errorMessages?: {
    tooLarge: string
    typeNotSupported: string
    generalError: string
    connectionError: string
  }
  labelUploadFailed: string
  tooltipPosition?: 'topRight' | 'bottomRight'
}

export const FileInfo: React.FC<FileInfoProps> = ({
  name,
  status,
  type,
  size,
  typeError,
  loading,
  errorMessages,
  labelUploadFailed,
  tooltipPosition
}) => {
  const errorMessage = useMemo(() => {
    if (!typeError || !errorMessages) {
      return '-'
    }

    if (typeError.tooLarge) {
      return errorMessages.tooLarge
    }
    if (typeError.typeNotSupported) {
      return errorMessages.typeNotSupported
    }
    if (typeError.generalError) {
      return errorMessages.generalError
    }
    if (typeError.connectionError) {
      return errorMessages.connectionError
    }
    return '-'
  }, [typeError, errorMessages])

  return (
    <div className="flex items-center justify-between pr-1">
      <div className="flex items-center gap-4">
        {status || loading ? (
          <Icon name="music-note" width={16} height={16} />
        ) : (
          <Icon
            name="no-music-note"
            width={16}
            height={16}
            className="text-[#727272]"
          />
        )}
        <p
          className={`text-center text-sm font-medium ${
            status || loading ? 'text-[#d1d1d1]' : 'text-[#727272]'
          } max-w-[315px] sm:max-w-[390px] lg:max-w-[490px] truncate mr-2`}
        >
          {name}
        </p>
      </div>
      <div className="flex items-center flex-row gap-1">
        {loading && <FileSize size={size} type={type} />}

        {!loading && !status && !typeError && (
          <FileSize size={size} type={type} />
        )}

        {!loading && !typeError && status && (
          <Icon
            name="check"
            width={16}
            height={16}
            className="text-[#858585]"
          />
        )}
        {typeError && !loading && (
          <div className="relative group ">
            <div>
              <div className="flex items-center gap-1 cursor-default">
                <Icon
                  name="alert-round"
                  width={12}
                  height={12}
                  className="text-[#f8594e] w-3 h-3 "
                />
                <small className="text-[#f8594e] text-xs font-medium">
                  {labelUploadFailed}
                </small>
              </div>
              <Tooltip
                className={`z-[100] absolute hidden group-hover:flex bg-gray-800 text-white p-2 rounded-md right-0  w-64 z-1 ${
                  tooltipPosition === 'topRight' ? 'bottom-[25px]' : 'top-6'
                }`}
                title={errorMessage}
                nozzle
                topRight={tooltipPosition === 'bottomRight'}
                bottomRight={tooltipPosition === 'topRight'}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
