import axios, { CancelToken } from 'axios'
import { create } from 'zustand'
import { FileQueued, FileQueuedStatus, FileUpload } from '../types'

export interface UseStoreUploadQueue {
  files: FileQueued[]
  progressTotal: number
  add(i: { file: FileUpload }): void
  addFiles(files: FileUpload[]): void
  remove(file: FileUpload): void
  clear(): void
  error(i: { file: FileUpload; message: string }): void
  success(i: { file: FileUpload }): void
  uploadFile(i: { file: FileUpload; cancelToken: CancelToken }): void
  uploadProgress(i: { file: FileUpload; progressEvent: ProgressEvent }): void
  uploadProgressTotal(): void
}

const MESSAGE_CANCEL_UPLOAD = 'upload canceled by user'

export const useStoreUploadQueue = create<UseStoreUploadQueue>((set, get) => ({
  files: [],
  progressTotal: 0,
  addFiles: (files) => {
    files.forEach((file) => get().add({ file }))
    set(() => ({ progressTotal: 0 }))
  },
  add: ({ file }) => {
    let source: FileQueued['source']
    let loading: FileQueuedStatus['loading'] = false
    let error: FileQueuedStatus['error'] = false
    let typeError: FileQueuedStatus['typeError']

    if (file.error) {
      error = true
      typeError = file.typeError
    } else {
      loading = true
      source = axios.CancelToken.source()
      const cancelToken = source.token
      get().uploadFile({ file, cancelToken })
    }

    set(() => ({
      files: [
        ...get().files,
        {
          file,
          source,
          progress: 0,
          loading,
          error,
          typeError,
          onRemove: () => get().remove(file)
        }
      ]
    }))
  },
  remove: (file) => {
    const filesModified = get().files
    const index = filesModified.findIndex(
      (i) => i && i.file.signedUrl === file.signedUrl
    )

    if (index >= 0) {
      // cancel download file
      filesModified[index]?.source?.cancel(MESSAGE_CANCEL_UPLOAD)

      // remove item from list
      filesModified.splice(index, 1)
      set(() => ({ files: [...filesModified] }))
    }
    get().uploadProgressTotal()
  },
  clear: () => {
    get()
      .files.slice()
      .reverse()
      .forEach((i) => get().remove(i.file))
    get().uploadProgressTotal()
  },
  error: ({ file, message }) => {
    // upload cancel by user
    if (message === MESSAGE_CANCEL_UPLOAD) {
      return
    }

    // set file as failed
    const filesModified = get().files
    const index = filesModified.findIndex(
      (i) => i && i.file.signedUrl === file.signedUrl
    )

    if (index >= 0) {
      filesModified[index] = {
        ...filesModified[index],
        loading: false,
        success: false,
        progress: 0,
        error: true,
        typeError: {
          generalError: true
        }
      }
      set(() => ({ files: [...filesModified] }))
    }
    get().uploadProgressTotal()
  },
  success: async ({ file }) => {
    // set file as successed
    const filesModified = get().files
    const index = filesModified.findIndex(
      (i) => i && i.file.signedUrl === file.signedUrl
    )

    if (index >= 0) {
      filesModified[index] = {
        ...filesModified[index],
        loading: false,
        success: true,
        error: false,
        progress: 100
      }
      set(() => ({ files: [...filesModified] }))
    }
    get().uploadProgressTotal()
    file.onSuccessfulUpload?.(file)
  },
  uploadFile: async ({ file, cancelToken }) => {
    try {
      await axios({
        method: 'PUT',
        url: file.signedUrl,
        data: file.metadata,
        headers: { 'Content-Type': file.metadata.type },
        cancelToken,
        onUploadProgress: (progressEvent) => {
          get().uploadProgress({ file, progressEvent })
        }
      })
      get().success({ file })
    } catch (error: any) {
      get().error({ file, message: error.message })
    }
  },
  uploadProgress: ({ file, progressEvent }) => {
    const filesModified = get().files
    const index = filesModified.findIndex(
      (i) => i && i.file.signedUrl === file.signedUrl
    )

    if (index >= 0) {
      filesModified[index] = {
        ...filesModified[index],
        progress: Math.round((progressEvent.loaded * 100) / progressEvent.total)
      }
      set(() => ({ files: [...filesModified] }))
      get().uploadProgressTotal()
    }
  },
  uploadProgressTotal: () => {
    const { files } = get()
    const total = files.filter((file) => !file.error).length

    if (!files.length || !total) {
      set(() => ({ progressTotal: 0 }))
      return
    }

    const totalProgress = files.reduce(
      (acc, file) => acc + (file.progress || 0),
      0
    )
    set(() => ({ progressTotal: Math.round(totalProgress / total) }))
  }
}))
