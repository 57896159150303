/* eslint-disable camelcase */
type DeviceInfo = {
  device_category: 'smartphone' | 'tablet' | 'desktop'
  device_os: 'Windows' | 'MacOS' | 'Linux' | 'iOS' | 'Android' | 'Other'
}

export const getDeviceInfo = (): DeviceInfo => {
  const userAgent = navigator.userAgent.toLowerCase()
  const platform = navigator.platform.toLowerCase()

  let device_category: 'smartphone' | 'tablet' | 'desktop'
  let device_os: 'Windows' | 'MacOS' | 'Linux' | 'iOS' | 'Android' | 'Other'

  if (/win/.test(platform)) {
    device_os = 'Windows'
  } else if (/mac/.test(platform)) {
    device_os = /iphone|ipad|ipod/.test(userAgent) ? 'iOS' : 'MacOS'
  } else if (/android/.test(userAgent)) {
    device_os = 'Android'
  } else if (/linux/.test(platform)) {
    device_os = 'Linux'
  } else {
    device_os = 'Other'
  }

  if (
    /mobile|android|iphone|ipod|blackberry|mini|windows\sce|palm/i.test(
      userAgent
    )
  ) {
    device_category = 'smartphone'
  } else if (
    /tablet|ipad|playbook|silk/i.test(userAgent) ||
    (navigator.maxTouchPoints > 1 && /mac/.test(platform))
  ) {
    device_category = 'tablet'
  } else {
    device_category = 'desktop'
  }

  return { device_category, device_os }
}
