import { gql } from 'graphql-request'
import {
  NotificationFeed,
  NotificationFeedFilter,
  NotificationFeedPagination
} from '../../../types/notification.types'

export interface NotificationCli {
  markMessageAs: (data: {
    messageIds: string[]
    read?: boolean
    seen: boolean
  }) => Promise<boolean>
  notificationFeed: (
    pagination: NotificationFeedPagination,
    filter: NotificationFeedFilter
  ) => Promise<NotificationFeed>
}

const Notification = (graphQL: any): NotificationCli => {
  const notificationFeed: NotificationCli['notificationFeed'] = async (
    pagination,
    filter
  ) => {
    const query = gql`
      query NotificationFeed(
        $pagination: Pagination!
        $filter: NotificationFeedFilter
      ) {
        notificationFeed(pagination: $pagination, filter: $filter) {
          unreadCount
          unseenCount
          pageInfo {
            hasNextPage
          }
          messages {
            id
            content
            sentAt
            read
            seen
            payload
            lastSeenAt
          }
        }
      }
    `

    const result = await graphQL({
      query,
      variables: {
        filter,
        pagination
      }
    })

    return result?.notificationFeed
  }

  const markMessageAs: NotificationCli['markMessageAs'] = async ({
    messageIds,
    read,
    seen
  }) => {
    const query = gql`
      mutation MarkMessageAs(
        $messageIds: [String!]!
        $read: Boolean!
        $seen: Boolean
      ) {
        markMessageAs(messageIds: $messageIds, read: $read, seen: $seen)
      }
    `

    await graphQL({
      query,
      variables: {
        messageIds,
        read,
        seen
      }
    })

    return true
  }

  return {
    markMessageAs,
    notificationFeed
  }
}

export default Notification
