interface ProgressBarProps {
  progress: number
}

export const ProgressBar: React.FC<ProgressBarProps> = ({ progress }) => {
  return (
    <div className="relative w-full my-6">
      <div
        className="h-[1px] w-full"
        style={{ backgroundColor: 'rgba(255, 255, 255, 0.1)' }}
      />
      <div
        className="absolute top-0 h-[1px]"
        style={{
          width: `${progress}%`,
          backgroundColor: '#3CDA71'
        }}
      />
    </div>
  )
}
