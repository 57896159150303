import React, { useContext } from 'react'
import { useCampaignCountdown } from '../../../../../packages/web-app-shell/src/hooks/campaign/use-campaing-countdown/use-campaign-countdown'
import { Countdown } from '../../../../../packages/web-app-shell/src/components/countdown/countdown'
import { ContextShell } from '../../../../../packages/web-app-shell/src'

export const CountdownModal: React.FC = () => {
  const { campaign } = useContext(ContextShell)

  const campaignCountdown = useCampaignCountdown(
    campaign?.type === 'DAYONEDISCOUNT' ? 1000 : 1000 * 60
  )

  return (
    <Countdown
      show
      theme="gray"
      countdown={campaignCountdown.countdown}
      showDays={campaign?.type !== 'DAYONEDISCOUNT'}
    />
  )
}
