import { ReactElement } from 'react'
import { DownloadQueueContainer } from '..'
import { ValidateUserTerms } from '../../modules/accept-terms'
import { UserSubscribePending } from '../../modules/subscribe-pending'
import { AppFrameWrapper } from './app-frame-wrapper'
import { AuthenticatedContainerType } from './types'
import { EventPlaylistPage } from '../../modules/playlist/event-playlist-page'
import { ModalCampaign } from '../../modules/modal-campaign'

export const AuthenticatedContainer = ({
  children
}: AuthenticatedContainerType): ReactElement => {
  return (
    <AppFrameWrapper>
      <>
        <UserSubscribePending />
        <ValidateUserTerms />
        <EventPlaylistPage />
        <ModalCampaign />
        <DownloadQueueContainer />
        {children}
      </>
    </AppFrameWrapper>
  )
}
