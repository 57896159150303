import React from 'react'
import { IconProps } from './icons.types'

export const NoMusic: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M35.3656 19.9791V4.33873C35.3656 3.24282 34.326 2.44518 33.2675 2.72887L15.1325 7.58889C14.4039 7.78416 13.8973 8.44444 13.8973 9.19875V13.8333M35.3656 19.9791V26.2663C35.3656 27.7793 34.3466 29.1025 32.8838 29.489L30.2406 30.1874M35.3656 19.9791L27.5821 22.0753C26.1268 22.4672 25.1156 23.7868 25.1156 25.2939V25.4739M13.8973 25.7603L6.1306 27.8128C4.66787 28.1994 3.64893 29.5226 3.64893 31.0355V32.9663C3.64893 35.1533 5.7195 36.7477 7.83392 36.189L11.4156 35.2424C12.8783 34.8559 13.8973 33.5327 13.8973 32.0197V25.7603ZM13.8973 25.7603V22.1666M4.99902 5.49993L34.999 35.4999"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
