import React from 'react'
import { ContextProvider, PostHogProvider } from '../context'
import { ShellProps } from '../../types/shell.types'
import { ShellWrapper } from './wrapper'
import { Scripts } from '../../containers/root-container/scripts'

export const Shell: React.FC<ShellProps> = ({ children }) => {
  return (
    <PostHogProvider>
      <Scripts />
      <ContextProvider>
        <ShellWrapper>{children}</ShellWrapper>
      </ContextProvider>
    </PostHogProvider>
  )
}
