import React, { ReactElement, useContext } from 'react'
import { useToast } from '../hooks/toast'
import { useFailedAuthentication } from '../hooks/authentication/use-failed-authentication'
import { usePluginContext } from '../hooks/plugin/use-plugin-context'
import { AppContext } from './types'
import { useAnnouncement } from '../hooks/announcements/use-announcement'
import { ContextShell } from '../../../../packages/web-app-shell/src'

export const Context = React.createContext<AppContext>({
  userToken: null,
  toast: {
    list: [],
    add: () => {},
    onClearFixedToast: () => {}
  },
  failedAuthentication: {
    disableFetching: false,
    increaseErrorCount: () => {}
  },
  announcement: {
    title: null,
    description: null,
    startAt: null,
    image: null,
    name: null,
    buttonText: null,
    show: false,
    onCloseBanner: () => {},
    onClickBanner: () => {},
    showNewFeature: () => false,
    onClickNewFeature: () => {}
  },
  plugins: {
    loading: false,
    needUpdate: {
      stems: false,
      mastering: false,
      studio: false
    },
    needInstall: {
      stems: false,
      mastering: false,
      studio: false
    },
    release: {
      stems: null,
      mastering: null,
      studio: null
    },
    needUpdateDesktopApp: false,
    showNotification: false,
    setViewedNotification: () => {},
    checkVersionInstaled: async () => {},
    isVersionDesktopAppValid: () => true
  }
})

export const ContextProvider = ({
  children
}: {
  children: React.ReactNode
}): ReactElement => {
  const { userToken } = useContext(ContextShell)
  const toast = useToast()
  const plugins = usePluginContext()
  const announcement = useAnnouncement()
  const failedAuthentication = useFailedAuthentication()

  return (
    <Context.Provider
      value={{
        toast,
        plugins,
        userToken,
        failedAuthentication,
        announcement
      }}
    >
      {children}
    </Context.Provider>
  )
}
