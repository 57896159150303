import React from 'react'
import { IconProps } from './icons.types'

export const Arrows: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.37504 7.50427L6.04171 4.17094L2.70837 7.50427M5.83338 15.8376L5.83337 5.00427M17.2917 12.4959L13.9584 15.8293L10.625 12.4959M13.9584 14.9959L13.9584 4.1626"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
