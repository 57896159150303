import { useCallback, useEffect, useMemo, useState } from 'react'
import { useList, useUnmount } from 'react-use'
import { useUploadFiles } from '../use-upload-files'
import { UseStoreUploadQueue } from '../use-upload-queued'
import { useCreateTask } from '../use-create-task'
import { TaskFileUploaded, TaskOperations } from '../use-create-task/types'
import { FileQueued, FileUpload } from '../types'
import { removeFileExtension } from '../utils'
import { usePlaylist } from '../../playlist/use-playlist'

interface UseUpload {
  files: UseStoreUploadQueue['files']
  isSubmitted: boolean
  overQueued: File[]
  progressTotal: UseStoreUploadQueue['progressTotal']
  onFilesChange: (filesSelected: File[]) => void
  onClearFiles: () => void
  onSubmit: () => void
  failedFiles: FileQueued[]
  uploadedFiles: FileQueued[]
  isCompleted: boolean
}

interface Props {
  limit?: number
  maxSize?: number
  playlistId?: string | null
  operations: TaskOperations[]
}

export const useUpload = ({
  limit = 20,
  maxSize,
  operations,
  playlistId
}: Props): UseUpload => {
  const [overQueued, { set: setOverQueued, clear: clearOverQueued }] =
    useList<File>([])

  const [isSubmitted, setIsSubmitted] = useState(false)
  const [
    createdTasks,
    { push: pushCreatedTasks, clear: clearPushCreatedTasks }
  ] = useList<FileUpload>([])
  const [waitingQueued, { set: setWaitingQueued, clear: clearWaitingQueued }] =
    useList<FileUpload>([])

  const { createTask } = useCreateTask()
  const { addTaskToPlaylist } = usePlaylist()

  const handleCreateTask = useCallback(
    async (file: FileUpload) => {
      const fileTask: TaskFileUploaded = {
        provider: 'FILESYSTEM',
        tempLocation: file.tempLocation,
        name: removeFileExtension(file.metadata.name),
        input: file.metadata.name
      }
      const taskCreatedId = await createTask({
        file: fileTask,
        operations
      })

      if (playlistId) {
        addTaskToPlaylist({
          playlistId,
          taskId: taskCreatedId
        })
      }
      pushCreatedTasks(file)
    },
    [createTask, addTaskToPlaylist, operations, playlistId, pushCreatedTasks]
  )

  useEffect(() => {
    if (isSubmitted && waitingQueued.length) {
      waitingQueued.forEach((file) => handleCreateTask(file))
      clearWaitingQueued()
    }
  }, [isSubmitted, waitingQueued, handleCreateTask, clearWaitingQueued])

  const onSubmit = useCallback<UseUpload['onSubmit']>(() => {
    setIsSubmitted(true)
  }, [])

  const onSuccessfulUpload = useCallback(
    (file: FileUpload) => {
      if (isSubmitted) {
        handleCreateTask(file)
      } else {
        setWaitingQueued((prev) => [...prev, file])
      }
    },
    [isSubmitted, handleCreateTask, setWaitingQueued]
  )

  const {
    files,
    progressTotal,
    onSetFiles,
    onClearUploadFiles,
    failedFiles,
    uploadedFiles
  } = useUploadFiles({ maxSize, limit, onSuccessfulUpload })

  const isCompleted = useMemo(
    () =>
      createdTasks.length === 0
        ? false
        : files?.filter((file) => !file.error).length === createdTasks.length,
    [files, createdTasks]
  )

  const onFilesChange = useCallback(
    async (filesSelected: File[]) => {
      if (!filesSelected?.length) {
        return
      }

      const list = Array.from(filesSelected)

      if (list.length > limit) {
        setOverQueued(list.slice(limit) as any)
      }

      onSetFiles(list.slice(0, limit) as any)
    },
    [limit, setOverQueued, onSetFiles]
  )

  const onClearFiles = useCallback<UseUpload['onClearFiles']>(() => {
    clearOverQueued()
    onClearUploadFiles()
    clearWaitingQueued()
    clearPushCreatedTasks()
    setIsSubmitted(false)
  }, [
    clearOverQueued,
    clearWaitingQueued,
    onClearUploadFiles,
    clearPushCreatedTasks
  ])

  useUnmount(() => {
    onClearFiles()
  })

  return {
    files,
    isSubmitted,
    overQueued,
    progressTotal,
    onFilesChange,
    onClearFiles,
    onSubmit,
    failedFiles,
    uploadedFiles,
    isCompleted
  }
}
