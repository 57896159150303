import React from 'react'
import { IconProps } from './icons.types'

export const NoMusicNote: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17 12C17 5.99997 11.3389 5.99998 11.3389 2.45197V11.2501M4 3.99997L20 20M5.18994 18.3213V19.4798C5.18994 20.792 6.43228 21.7487 7.70094 21.4134L9.84994 20.8455C10.7276 20.6135 11.3389 19.8271 11.3389 18.9193V16.4548C11.3389 15.7987 10.7178 15.3204 10.0834 15.488L6.67894 16.3877C5.80131 16.6196 5.18994 17.4136 5.18994 18.3213Z"
      stroke="currentcolor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
)
