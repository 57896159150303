/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useContext, useMemo } from 'react'
import { useRouter } from 'next/router'
import { Icon } from '../../../components/icon'
import { FileQueued } from '../../../hooks/upload/types'
import { ContextShell } from '../../context'
import { ProgressBar } from './progress-bar'
import { FileInfo } from './file-info'
import { UploadAnimation } from './upload-animation'

interface CardUploadProps {
  onDismiss?: () => void
  files: FileQueued[]
  progressTotal: number
  overQueued: File[]
  limit: number
  limitFreeUser: number
  cancelUpload: () => void
  localizations: {
    uploading_1_file_title: string
    '1_file_uploaded_title': string
    uploading_5_files_title: string
    '5_files_uploaded_title': string
    upload_failed_plural: string
    bulk_upload_error_message_1: string
    bulk_upload_error_message_2: string
    bulk_upload_error_message_3: string
    bulk_upload_error_message_4: string
    'upload.uploading.error.title': string
    batch_upload_limit_message: string
    cancel_upload_cta: string
    message_error_improve: string
    upgrade_now_button: string
    upload_free_cta_upgrade: string
  }
  failedFiles: FileQueued[]
  uploadedFiles: FileQueued[]
}

export const CardUpload: React.FC<CardUploadProps> = ({
  onDismiss,
  files,
  progressTotal,
  overQueued,
  limit,
  cancelUpload,
  localizations,
  failedFiles,
  uploadedFiles,
  limitFreeUser
}) => {
  const { user } = useContext(ContextShell)
  const { push } = useRouter()

  const filesUploaded = useMemo(() => {
    const filesTotal = files?.length

    return `${uploadedFiles?.length}/${filesTotal}`
  }, [files, progressTotal])

  const totalFilesFailed = useMemo<number>(() => {
    return failedFiles?.length
  }, [files])

  const isFreeUser = useMemo(() => {
    return user?.subscription?.plan === 'free'
  }, [])

  const failedAllUploads = useMemo(
    () => files.length === failedFiles.length,
    [files, failedFiles]
  )

  const singularMessage = useMemo(() => files.length === 1, [files])

  const onGoPricing = useCallback(() => {
    push('/billing/pricing')
  }, [push])

  const tooltipPosition = useCallback(
    (index: number) => {
      if (index < 2) {
        return 'bottomRight'
      }

      if (index < files.length / 2) {
        return 'bottomRight'
      }
      return 'topRight'
    },
    [files]
  )

  return (
    <div className="w-full sm:w-[600px] lg:w-[768px] p-8 bg-[#1c1c1c] rounded-2xl max-h-[60vh] flex flex-col">
      <div className="flex justify-between items-start h-10">
        <div className="flex align-center gap-4">
          {failedAllUploads ? (
            <Icon name="upload-error" width={32} height={32} />
          ) : progressTotal !== 100 ? (
            <UploadAnimation />
          ) : (
            <Icon name="checkbox-cicle" width={32} height={32} />
          )}

          <div className="flex flex-col ">
            {failedAllUploads ? (
              <h2 className="text-white text-lg font-semibold ">
                {localizations['upload.uploading.error.title']}
              </h2>
            ) : progressTotal !== 100 ? (
              <h2 className="text-white text-lg font-semibold ">
                {singularMessage
                  ? localizations?.uploading_1_file_title
                  : localizations?.uploading_5_files_title?.replace(
                      '**5**',
                      filesUploaded
                    )}
              </h2>
            ) : (
              <h2 className="text-white text-lg font-semibold ">
                {singularMessage
                  ? localizations['1_file_uploaded_title']
                  : localizations['5_files_uploaded_title']?.replace(
                      '**5**',
                      filesUploaded
                    )}
              </h2>
            )}
            {progressTotal === 100 && totalFilesFailed > 0 && (
              <small className="text-gray-40 text-xs font-medium ">
                {localizations?.upload_failed_plural?.replace(
                  '**2**',
                  totalFilesFailed?.toString()
                )}
              </small>
            )}
          </div>
        </div>
        {onDismiss && (
          <button
            type="button"
            onClick={onDismiss}
            tabIndex={0}
            className=" bg-transparent border-0 focus:outline-none hover:cursor-pointer p-0"
          >
            <Icon name="close" width={20} height={20} className="text-white" />
          </button>
        )}
      </div>
      <div className="custom-scrollbar mt-9 h-auto min-h-[130px] max-h-[431px] overflow-y-auto">
        <div className="flex flex-col gap-5">
          {files?.map((file, index) => (
            <FileInfo
              key={file?.file?.signedUrl}
              name={file?.file?.metadata?.name}
              status={file?.success}
              type={file?.file?.metadata?.type}
              size={file?.file?.metadata?.size}
              typeError={file?.typeError}
              loading={file?.loading}
              errorMessages={{
                typeNotSupported: localizations?.bulk_upload_error_message_1,
                tooLarge: localizations?.bulk_upload_error_message_2,
                connectionError: localizations?.bulk_upload_error_message_3,
                generalError: localizations?.bulk_upload_error_message_4
              }}
              labelUploadFailed={localizations['upload.uploading.error.title']}
              tooltipPosition={tooltipPosition(index)}
            />
          ))}
        </div>

        {overQueued?.length > 0 && (
          <>
            {isFreeUser ? (
              <p className="text-[#d1d1d1] text-sm font-medium mb-5 mt-8">
                {localizations?.upload_free_cta_upgrade?.replace(
                  '**5**',
                  limitFreeUser?.toString()
                )}{' '}
                <button
                  type="button"
                  className="text-[#00d9e7] p-0 text-sm font-medium hover:opacity-70 bg-transparent border-0 focus:outline-none hover:cursor-pointer"
                  onClick={onGoPricing}
                >
                  {localizations?.upgrade_now_button}
                </button>
              </p>
            ) : (
              <p className="text-[#d1d1d1] text-sm font-medium mb-5 mt-8">
                {localizations?.batch_upload_limit_message?.replace(
                  '**20**',
                  limit.toString()
                )}
              </p>
            )}
            <div className="flex flex-col gap-5 mb-8">
              {overQueued?.map((file) => (
                <FileInfo
                  key={file?.name}
                  name={file?.name}
                  status={false}
                  type={file?.type}
                  size={file?.size}
                  loading={false}
                  labelUploadFailed={
                    localizations['upload.uploading.error.title']
                  }
                />
              ))}
            </div>
          </>
        )}
      </div>
      <ProgressBar progress={progressTotal} />

      <div className="flex items-center justify-between">
        {failedAllUploads ? (
          <p className="text-center text-[#a8a8a8] text-sm font-medium w-full">
            {localizations?.message_error_improve}
          </p>
        ) : (
          <>
            {progressTotal !== 100 ? (
              <button
                type="button"
                onClick={cancelUpload}
                tabIndex={0}
                className="p-0 text-center text-white text-sm font-semibold hover:opacity-70  bg-transparent border-0 focus:outline-none hover:cursor-pointer"
              >
                {localizations?.cancel_upload_cta}
              </button>
            ) : (
              <div />
            )}
            <b className="text-center text-[#a8a8a8] text-base font-medium ">
              {progressTotal}%
            </b>
          </>
        )}
      </div>
    </div>
  )
}
