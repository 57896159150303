import { useCallback, useContext, useMemo } from 'react'
import { config } from '../../../config'
import { Moises } from '../../../lib/cli'
import { ContextShell } from '../../../modules/context'
import { FileQueued, FileQueuedStatus, FileUpload } from '../types'
import { UseStoreUploadQueue, useStoreUploadQueue } from '../use-upload-queued'
import { isValidFileType } from '../utils'

interface UseUploadFiles {
  files: UseStoreUploadQueue['files']
  progressTotal: UseStoreUploadQueue['progressTotal']
  onSetFiles: (files: FileList) => void
  onClearUploadFiles: () => void
  failedFiles: FileQueued[]
  uploadedFiles: FileQueued[]
}

interface Props {
  limit?: number
  maxSize?: number
  onSuccessfulUpload?: FileUpload['onSuccessfulUpload']
}

const MoisesCLI = Moises({ apiEndpoint: config.api.endpoint })

export const useUploadFiles = ({
  onSuccessfulUpload,
  limit = 20,
  maxSize = 2254857830 // 2.1 GB
}: Props): UseUploadFiles => {
  const { userToken } = useContext(ContextShell)
  const {
    files,
    progressTotal,
    clear: clearQueuedUpload,
    addFiles: addFilesToUpload
  } = useStoreUploadQueue()

  const failedFiles = useMemo(
    () => files.filter((file) => file.error),
    // eslint-disable-next-line
    [files, progressTotal]
  )

  const uploadedFiles = useMemo(
    () => files.filter((file) => file.success),
    // eslint-disable-next-line
    [files, progressTotal]
  )

  const onSetFiles = useCallback<UseUploadFiles['onSetFiles']>(
    async (filesSelected) => {
      if (!filesSelected?.length) {
        return
      }

      try {
        MoisesCLI.auth(userToken)
        const countSignedUrls =
          filesSelected.length > limit ? limit : filesSelected.length
        const response = await MoisesCLI.uploadFiles({
          count: countSignedUrls,
          type: 'FILESYSTEM',
          resumable: false
        })

        const items: FileUpload[] = response?.signedUrls?.map(
          (signed, index) => {
            const file = filesSelected[index]
            const properties: FileQueuedStatus = {}

            if (file.size > maxSize) {
              properties.error = true
              properties.typeError = {
                tooLarge: true
              }
            } else if (!isValidFileType(file)) {
              properties.error = true
              properties.typeError = {
                typeNotSupported: true
              }
            } else if (!signed.signedUrl) {
              properties.error = true
              properties.typeError = {
                connectionError: true
              }
            }

            return {
              progress: 0,
              metadata: file,
              signedUrl: signed.signedUrl,
              tempLocation: signed.tempLocation,
              onSuccessfulUpload,
              ...properties
            }
          }
        )

        if (items.length) {
          addFilesToUpload(items)
        }
      } catch (e: any) {
        // eslint-disable-next-line no-console
        console.error('[Upload] Error to create signed urls', e)
      }
    },
    [maxSize, limit, userToken, addFilesToUpload, onSuccessfulUpload]
  )

  const onClearUploadFiles = useCallback<
    UseUploadFiles['onClearUploadFiles']
  >(async () => {
    clearQueuedUpload()
  }, [clearQueuedUpload])

  return {
    files,
    progressTotal,
    onSetFiles,
    onClearUploadFiles,
    failedFiles,
    uploadedFiles
  }
}
