import React, { useContext, useMemo, useState } from 'react'
import { useEffectOnce, useWindowSize } from 'react-use'
import { ContextProvider } from '../context'
import { AppFrameProps } from '../../types'
import { Content } from './content'
import { HeaderBar } from '../header-bar'
import { ContextShell } from '../../../../web-app-shell/src'

export const AppFrame: React.FC<AppFrameProps> = ({
  strings = {},
  children,
  onNavigate,
  currentPathName,
  navItems = []
}) => {
  const { user, silentRetry } = useContext(ContextShell)

  const { width } = useWindowSize()
  const isMobileViewport = useMemo(() => width < 639, [width])
  const [canShowHeader, setCanShowHeader] = useState(false)
  useEffectOnce(() => setCanShowHeader(true))

  return (
    <ContextProvider
      silentRetry={silentRetry}
      user={user as any}
      loadingUserInfo={false}
      onNavigate={onNavigate}
      currentPathName={currentPathName}
    >
      {isMobileViewport && canShowHeader && (
        <HeaderBar
          strings={strings}
          items={navItems}
          // error={hasError}
          // loading={loading}
        />
      )}

      <Content>{children}</Content>
    </ContextProvider>
  )
}
