import Head from 'next/head'
import { ReactElement } from 'react'
import { Scripts } from './scripts'
import { RootContainerType } from './types'

export const RootContainer: React.FC<RootContainerType> = ({
  includeTags = true,
  children
}): ReactElement => {
  return (
    <>
      <Head>
        <link rel="icon" href="/favicon-32x32.png" />
        <link rel="manifest" href="/manifest.json" />
        <meta name="theme-color" content="#000000" />
        {process.env.NEXT_PUBLIC_HOSTNAME !== 'studio.moises.ai' && (
          <meta name="robots" content="noindex, nofollow" />
        )}
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, user-scalable=no"
        />
        <meta
          name="keywords"
          content="track separation, vocal remover, karaoke tracks, ai mastering, free mastering"
        />

        {includeTags && (
          <>
            <title>
              Moises - The Musician&apos;s App | Remove vocals and instruments
              from any song
            </title>
            <meta
              name="description"
              content="The best app for practicing music. Remove vocals, separate instruments, master your tracks, and remix songs with the power of AI. Try it today!"
            />
            <meta
              property="og:title"
              content="Moises - The Musician's App | Remove vocals and instruments from any song"
            />
            <meta
              property="og:description"
              content="The best app for practicing music. Remove vocals, separate instruments, master your tracks, and remix songs with the power of AI. Try it today!"
            />
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://app.moises.ai" />
            <meta
              property="og:image"
              content="https://app.moises.ai/moises-acqua.png"
            />
          </>
        )}
      </Head>
      <Scripts />
      <div id="moises-app">{children}</div>
    </>
  )
}
