import { GraphQLClient } from 'graphql-request'
import { getClientHeaders } from '../../config'
import Campaign from './campaign'
import Languages from './languages'
import Pair from './pair'
import Playlist from './playlist'
import ReferralLink from './referral-link'
import Subscription from './subscription'
import Task from './task'
import User from './user'
import { trigger } from '../events'
import { UserToken } from '../../context/types'
import SupportedInstruments from './supported-instruments'
import Announcement from './announcement'
import Group from './group'
import Notification from './notification'

type MoisesCli = ReturnType<typeof Pair> &
  ReturnType<typeof Announcement> &
  ReturnType<typeof Playlist> &
  ReturnType<typeof Subscription> &
  ReturnType<typeof Campaign> &
  ReturnType<typeof ReferralLink> &
  ReturnType<typeof Task> &
  ReturnType<typeof Languages> &
  ReturnType<typeof SupportedInstruments> &
  ReturnType<typeof Notification> &
  ReturnType<typeof Group> &
  ReturnType<typeof User> & {
    session: {
      authToken: string | UserToken
      apiEndpoint: string
    }
    auth: (authToken: string | UserToken) => void
    graphQL: ({
      query,
      variables
    }: {
      query: string
      variables?: any
    }) => Promise<any>
  }

export const Moises = (config: any): MoisesCli => {
  const session = {
    authToken: config.authToken || process.env.MOISES_AUTH_TOKEN,
    apiEndpoint: config.apiEndpoint || 'https://api.moises.ai'
  }

  const graphQLClient = new GraphQLClient(`${session.apiEndpoint}/graphql`, {
    credentials: 'include',
    mode: 'cors'
  })

  function auth(authToken: string | UserToken): void {
    session.authToken = authToken
  }

  async function graphQL({
    query,
    variables
  }: {
    query: string
    variables?: any
  }): Promise<any> {
    if (!session.authToken) {
      throw new Error('You need to set your apiKey')
    }

    try {
      const response = await graphQLClient.request(query, variables, {
        Authorization: session.authToken,
        ...getClientHeaders()
      })

      return response
    } catch (e: any) {
      const isAuthFailed = e?.response?.errors?.some(
        (i: any) => i?.extensions?.code === 'UNAUTHENTICATED'
      )

      if (isAuthFailed) {
        trigger('firebase:refresh-token')
      }

      console.log('UNAUTHENTICATED?', isAuthFailed) // eslint-disable-line no-console
      console.error('Error fetching graphql') // eslint-disable-line no-console
      throw new Error(e)
    }
  }

  const notification = Notification(graphQL)

  const subscription = Subscription(graphQL)

  const pair = Pair(graphQL)

  const announcement = Announcement(graphQL)

  const task = Task(graphQL)

  const playlist = Playlist(graphQL)

  const campaign = Campaign(graphQL)

  const referralLink = ReferralLink(graphQL)

  const user = User(graphQL)

  const group = Group(graphQL)

  const languages = Languages(graphQL)

  const supportedInstruments = SupportedInstruments(graphQL)

  return {
    auth,
    graphQL,
    session,
    ...user,
    ...pair,
    ...task,
    ...group,
    ...playlist,
    ...campaign,
    ...languages,
    ...notification,
    ...referralLink,
    ...subscription,
    ...announcement,
    ...supportedInstruments
  }
}
