'use client'

import posthog from 'posthog-js'
import { PostHogProvider as PHProvider } from 'posthog-js/react'
import { useEffect } from 'react'

declare module 'posthog-js' {
  interface PostHog {
    isInitialized?: boolean
  }
}

export const PostHogProvider = ({
  children
}: {
  children: React.ReactNode
}): JSX.Element => {
  const posthogKey = process.env.NEXT_PUBLIC_POSTHOG_KEY
  const posthogHost = process.env.NEXT_PUBLIC_POSTHOG_HOST

  useEffect(() => {
    if (typeof window === 'undefined') return

    if (!posthog.isInitialized) {
      if (!posthogKey || !posthogHost) {
        // eslint-disable-next-line no-console
        console.error('PostHog: key or host not defined')
        return
      }

      posthog.init(posthogKey, {
        api_host: posthogHost,
        person_profiles: 'identified_only',
        disable_session_recording: true,
        disable_web_experiments: false,
        capture_pageview: false,
        autocapture: false
      })
      posthog.isInitialized = true
    }
  }, [posthogHost, posthogKey])

  return <PHProvider client={posthog}>{children}</PHProvider>
}
