// import Script from 'next/script'
// import { getEnv } from '../../config'

export const Scripts: React.FC = () => (
  <>
    {/* rudderstack lib */}
    {/* <Script
      id="rudderstack-lib"
      src={`/assets/javascript/rudderanalytics${
        getEnv() === 'production' ? '' : '-stage'
      }.min.js`}
      strategy="beforeInteractive"
    /> */}
  </>
)
