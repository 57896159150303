import { formatFileType } from '../../../hooks/upload/utils'
import { formatBytes } from '../../../utils/format-bytes'

interface FileSizeProps {
  size: number
  type: string
}

export const FileSize: React.FC<FileSizeProps> = ({ type, size }) => {
  return (
    <>
      <p className="text-right text-[#727272] text-xs font-medium ">
        {formatFileType(type)}
      </p>
      <div className="w-1 h-1 bg-[#545454] rounded-full" />
      <p className="text-right text-[#727272] text-xs font-medium ">
        {formatBytes(size)}
      </p>
    </>
  )
}
