import { Button, Icon, Modal } from 'scala'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useRouter } from 'next/router'
import { usePersistentDismissible } from '../../hooks/use-persistent-dismissable'
import styles from './styles.module.scss'
import { ContextShell } from '../../../../../packages/web-app-shell/src'
import { CountdownModal } from './modal-countdown'

export const ModalCampaign: React.FC = () => {
  const { push } = useRouter()

  const {
    campaign,
    user,
    toast: { add: addToast }
  } = useContext(ContextShell)

  const enabled = useMemo(() => !!campaign?.id, [campaign])

  const isDayOne = useMemo(
    () => campaign?.type === 'DAYONEDISCOUNT',
    [campaign]
  )

  const { show, onDismiss, getStorage } = usePersistentDismissible({
    id: `modal-${campaign?.id}`,
    enabled
  })

  const onClick = useCallback(() => {
    onDismiss()
    push('/billing/pricing/?source=offer_main_banner')
  }, [push, onDismiss])

  /**
   * Shows toast (floating banner) after day 1 after show the modal
   */
  const [showed, setShowed] = useState(false)
  const [showToast, setShowToast] = useState(false)
  const { show: showToastSession, onDismiss: onDismissToast } =
    usePersistentDismissible({
      id: `toast-${campaign?.id}`,
      type: 'session',
      enabled
    })

  useEffect(() => {
    const storageModal = getStorage()

    if (!showToast && storageModal?.date) {
      const diff = Math.abs(
        new Date().getTime() - new Date(storageModal.date).getTime()
      )
      const diffDays = diff / (1000 * 60 * 60 * 24)

      if (diffDays >= 1) {
        setShowToast(true)
      }
    }
  }, [show, showToast, getStorage])

  useEffect(() => {
    if (
      showToastSession &&
      showToast &&
      !showed &&
      campaign?.floatingBannerWeb
    ) {
      setShowed(true)
      addToast({
        icon: null,
        fixed: true,
        type: 'success',
        closable: true,
        title: campaign?.floatingBannerWeb?.title,
        description: campaign?.floatingBannerWeb?.description,
        buttonText: campaign?.floatingBannerWeb?.cta,
        onConfirm: onClick
      })
      onDismissToast()
    }
  }, [
    showToast,
    showed,
    showToastSession,
    addToast,
    onClick,
    onDismissToast,
    campaign?.floatingBannerWeb
  ])

  if (!user || !show || !campaign || !campaign?.mainBannerWeb) {
    return null
  }

  return (
    <Modal
      label="Campaign"
      targetId="shell-app"
      isOpen={show}
      onClose={onDismiss}
      shouldCloseOnOverlayClick
    >
      <div className={styles.container}>
        {campaign?.mainBannerImages?.size2x && (
          <img
            alt={styles.title}
            src={campaign?.mainBannerImages?.size2x}
            className={styles.image}
          />
        )}
        <button
          className={styles.close}
          type="button"
          onClick={onDismiss}
          tabIndex={0}
        >
          <Icon name="close" />
        </button>
        <div className={styles.modalBody}>
          <div className={styles.modalText}>
            {isDayOne ? (
              <>
                <h1 className={styles.highlightTitle}>
                  {campaign?.mainBannerWeb?.title?.replace(
                    /\*{2}(.*?)\*{2}/g,
                    '$1'
                  )}
                </h1>
                <p className={styles.highlightDescription}>
                  {campaign?.mainBannerWeb?.description?.replace(
                    /\*{2}(.*?)\*{2}/g,
                    '$1'
                  )}
                </p>
              </>
            ) : (
              <>
                <h1 className={styles.title}>
                  {campaign?.mainBannerWeb?.title?.replace(
                    /\*{2}(.*?)\*{2}/g,
                    '$1'
                  )}
                </h1>
                <p className={styles.description}>
                  {campaign?.mainBannerWeb?.description?.replace(
                    /\*{2}(.*?)\*{2}/g,
                    '$1'
                  )}
                </p>
              </>
            )}
          </div>

          {isDayOne && <CountdownModal />}

          <Button
            className={styles.cta}
            title={campaign?.mainBannerWeb?.cta}
            small
            radius={1}
            onClick={onClick}
          />
        </div>
      </div>
    </Modal>
  )
}
