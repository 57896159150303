export const convertSizeToMB = (sizeInBytes: number): number => {
  return sizeInBytes / (1024 * 1024)
}

export const formatFileType = (mimeType: string): string | null => {
  if (!mimeType) return null

  const [, subtype] = mimeType.split('/')

  const formattedSubtype = subtype
    ? subtype.replace(/(x-|-)/g, ' ').toUpperCase()
    : null

  return mimeType === 'audio/mpeg' ? 'MP3' : formattedSubtype
}

export const isValidFileType = (file: File): boolean => {
  const allowedMIMETypes = [
    'audio/mpeg', // mp3
    'audio/x-mpeg',
    'video/mpeg',
    'video/x-mpeg',
    'audio/wav',
    'audio/x-wav', // alternative wav
    'audio/vnd.wave', // alternative wav
    'audio/flac',
    'audio/x-flac',
    'audio/mp4', // m4a
    'audio/m4a', // alternative m4a
    'audio/x-m4a', // alternative m4a
    'video/mp4', // mp4
    'video/x-mp4', // alternative mp4
    'video/quicktime', // mov
    'audio/x-ms-wma', // wma
    'video/x-ms-wmv', // wma (vídeo)
    'video/x-ms-wma', // wma (vídeo)
    'audio/aac',
    'audio/x-aac', // alternative aac
    'audio/webm',
    'audio/x-webm',
    'video/webm',
    'video/x-webm',
    'audio/ogg',
    'audio/x-ogg',
    'video/ogg',
    'video/x-ogg',
    'audio/aiff'
  ]

  if (!file.type) {
    const ext = file.name?.split('.')?.pop()?.toLowerCase()
    return ext === 'wma' || ext === 'aiff'
  }

  return allowedMIMETypes.includes(file.type)
}

export const removeFileExtension = (fileName: string): string => {
  const lastDotIndex = fileName.lastIndexOf('.')
  if (lastDotIndex === -1) {
    return fileName
  }
  return fileName.slice(0, lastDotIndex)
}
