import React from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import styles from './content.module.scss'

interface Props {
  children?: React.ReactNode
}

export const Content: React.FC<Props> = ({ children }) => {
  return (
    <div className={styles.container}>
      <DndProvider backend={HTML5Backend}>
        <div className={styles.content}>{children}</div>
      </DndProvider>
    </div>
  )
}
