import { gql } from 'graphql-request'
import { type ReferralLink as IReferralLink } from '../../../types/referral-link.types'

interface ReferralLinkCli {
  fetchReferralLinks(): Promise<ReferralLinkResponse>
}

export type ReferralLinkResponse = {
  referralLinks: IReferralLink[]
}

const ReferralLink = (graphQL: any): ReferralLinkCli => {
  const fetchReferralLinks = async (): Promise<any> => {
    const query = gql`
      query ReferralLink {
        referralLinks {
          id
        }
      }
    `
    const result = await graphQL({ query })
    return result
  }

  return {
    fetchReferralLinks
  }
}

export default ReferralLink
