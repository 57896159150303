import { UserContext } from '../../../types'
import { FilteredAttributes, RawAttributesType, UserAttributes } from './types'

const skillLevels: Record<number, string> = {
  0: 'beginner',
  10: 'intermediate',
  20: 'pro'
}

const skill = (value: number): string => skillLevels[value] || 'unknown'

const formatInstruments = (
  instruments: UserContext['instruments'] = []
): Record<string, string> => {
  const instrumentMap = instruments.reduce(
    (acc, { skillLevel, instrumentId }) => {
      const formattedId = instrumentId.replace(/-/g, '_')
      acc[formattedId] = skill(skillLevel)
      return acc
    },
    {} as Record<string, string>
  )

  return instrumentMap
}

const filterAttributes = (
  rawAttributes: RawAttributesType
): FilteredAttributes => {
  return Object.fromEntries(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Object.entries(rawAttributes).filter(([_, value]) => {
      if (value === undefined || value === null) return false
      if (typeof value === 'string' && value.trim() === '') return false
      if (Array.isArray(value) && value.length === 0) return false
      return true
    })
  ) as FilteredAttributes
}

export const formatUserAttributes = (user: UserContext): UserAttributes => {
  const goals = user?.goals

  const preferences = user?.preferences
  const preferencesComunication = preferences?.communication

  const subscription = user?.subscription
  const subscriptionDetails = subscription?.details

  const instruments = user?.instruments

  const goalsCustom = goals
    ?.map((goal: string) => goal.replace('goal-', ''))
    .join(', ')

  const preferencesCustom = {
    is_chords_simplified: preferences?.chordSkillLevel === 'BEGINNER',
    communication_activity_email: preferencesComunication?.activity?.email,
    communication_activity_push: preferencesComunication?.activity?.push,
    communication_collaboration_email:
      preferencesComunication?.collaboration?.email,
    communication_collaboration_push:
      preferencesComunication?.collaboration?.push,
    communication_updates_email: preferencesComunication?.updates?.email,
    communication_updates_push: preferencesComunication?.updates?.push,
    default_separation: preferences?.defaultSeparation?.stems?.join(', '),
    is_demo_playlist_hidden_from_library:
      preferences?.demoPlaylist?.hideFromLibrary,
    is_demo_playlist_notification_opt_out: preferences?.demoPlaylist?.optOut
  }

  const subscriptionCustom = {
    is_subscription_active: subscription?.active,
    available_credits: subscription?.availableCredits,
    current_monthly_usage: subscription?.currentMonthlyUsage,
    is_subscription_auto_renew: subscription?.details?.autoRenew,
    subscription_expiration_date: subscriptionDetails?.expireDate,
    subscription_plan_cycle: subscriptionDetails?.planCycle,
    is_premium: subscription?.isPremium,
    is_pro: subscription?.isPro,
    plan: subscription?.plan,
    subscription_type: subscription?.subscriptionType
  }

  const instrumentsCustom = formatInstruments(instruments)

  const rawAttributes = {
    id: user?.id,
    last_sign_in_at: user?.lastSignInAt,
    created_at: user?.createdAt,
    is_email_verified: user?.emailVerified,
    goals: goalsCustom,
    is_employee: user?.isEmployee,
    ...preferencesCustom,
    ...subscriptionCustom,
    ...instrumentsCustom
  }

  return filterAttributes(rawAttributes as RawAttributesType)
}
