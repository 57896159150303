import { gql } from 'graphql-request'

interface PairCli {
  authorizeApp(data: { code: string }): Promise<any>
}

const Pair = (graphQL: any): PairCli => {
  const authorizeApp = async ({ code }: { code: string }): Promise<any> => {
    const variables = {
      userCode: parseInt(code, 10)
    }
    const query = gql`
      mutation AuthorizeApp($userCode: Int!) {
        authorizeApp(userCode: $userCode)
      }
    `
    const result = await graphQL({ query, variables })
    return result
  }

  return {
    authorizeApp
  }
}

export default Pair
