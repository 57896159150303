import { ReactLottiePlayer } from '../../../components/react-lottie-player'
import { animation } from './animation'

export const UploadAnimation: React.FC = () => {
  return (
    <div className="w-8 h-8">
      <ReactLottiePlayer loop autoplay src={animation} />
    </div>
  )
}
