import React from 'react'
import { IconProps } from './icons.types'

export const Sorting: React.FC<IconProps> = ({ width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 13 7"
    fill="none"
  >
    <path
      d="M6.16992 0.723022L12.1699 6.9584H0.169922L6.16992 0.723022Z"
      fill="currentColor"
    />
  </svg>
)
