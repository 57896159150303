import * as Sentry from '@sentry/nextjs'
import { useAsyncFn } from 'react-use'
import { useContext } from 'react'
import { config } from '../../../config'
import { Moises } from '../../../lib/cli'
import { CreateTaskOperation, CreateTasks } from './types'
import { ContextShell } from '../../../modules/context'

const MoisesCLI = Moises({ apiEndpoint: config.api.endpoint })

export const useCreateTask = (): CreateTasks => {
  const { user, userToken } = useContext(ContextShell)

  const [state, createTask] = useAsyncFn(
    async ({ file, operations }: CreateTaskOperation) => {
      MoisesCLI.auth(userToken)

      try {
        const result = await MoisesCLI.createTask({
          taskFile: {
            ...file,
            provider: file.provider.toUpperCase()
          },
          operations
        })
        return result
      } catch (e: any) {
        Sentry.captureMessage('[Upload] Error on create task', {
          level: 'error',
          extra: {
            userId: user?.id,
            error: e
          }
        })
        throw new Error(e)
      }
    },
    [userToken]
  )

  return {
    ...state,
    createTask
  }
}
