import { gql } from 'graphql-request'
import {
  TrackOrderField,
  TrackOrderSort,
  TrackDisplaySet
} from '../../../types'

interface PlaylistCli {
  track(data: { id: string }): Promise<any>
  createPlaylist(name: string): Promise<any>
  deletePlaylist(data: { playlistId: string }): Promise<any>
  unsubscribeFromPlaylist(data: { playlistId: string }): Promise<any>
  fetchPlaylists(data: {
    sort?: TrackOrderSort
    display?: TrackDisplaySet
  }): Promise<any>
  fetchPlaylist(data: {
    playlistId: string
    field?: TrackOrderField
    sort?: TrackOrderSort
  }): Promise<any>
  addTracksToPlaylist(data: {
    playlistId: string
    trackIds: string[]
  }): Promise<boolean>
  removeTracksFromSetlist(data: {
    setlistId: string | null
    trackIds: string[]
  }): Promise<any>
  reorderTracksOnPlaylist(data: {
    playlistId: string
    order: number
    moveBefore: number
  }): Promise<any>
  updatePlaylist(data: {
    playlistId: string
    name: string
    description: string | null
  }): Promise<any>
  sharePlaylist(data: { playlistId: string; enabled: boolean }): Promise<any>
  joinPlaylist(data: { playlistId: string }): Promise<any>
  membersEditPlaylist(data: { id: string; viewOnly: boolean }): Promise<any>
  recentContacts(data: { playlistId: string }): Promise<any>
  sendPlaylistInvite(data: { playlistId: string; userId: string }): Promise<any>
  removeGuestAccess(data: { playlistId: string; userId: string }): Promise<any>
}

const Playlist = (graphQL: any): PlaylistCli => {
  const createPlaylist = async (name: string): Promise<any> => {
    const variables = {
      name
    }
    const query = gql`
      mutation CreatePlaylist($name: String, $description: String) {
        createPlaylist(name: $name, description: $description) {
          id
          name
          description
        }
      }
    `
    const result = await graphQL({ query, variables })
    return result.createPlaylist
  }

  const addTracksToPlaylist: PlaylistCli['addTracksToPlaylist'] = async ({
    playlistId,
    trackIds
  }) => {
    const variables = {
      playlistId,
      trackIds
    }
    const query = gql`
      mutation AddTracksToPlaylist($playlistId: ID!, $trackIds: [ID!]!) {
        addTracksToPlaylist(playlistId: $playlistId, trackIds: $trackIds) {
          success {
            total
          }
        }
      }
    `
    const result = await graphQL({ query, variables })
    return result
  }

  const removeTracksFromSetlist = async ({
    setlistId,
    trackIds
  }: {
    setlistId: string
    trackIds: string[]
  }): Promise<any> => {
    const variables = {
      setlistId,
      trackIds
    }
    const query = gql`
      mutation RemoveTracksFromSetlist($setlistId: ID!, $trackIds: [String!]!) {
        removeTracksFromSetlist(setlistId: $setlistId, trackIds: $trackIds)
      }
    `
    const result = await graphQL({ query, variables })
    return result
  }

  const deletePlaylist = async ({
    playlistId
  }: {
    playlistId: string
  }): Promise<any> => {
    const variables = {
      id: playlistId
    }
    const query = gql`
      mutation DeletePlaylist($id: ID!) {
        deletePlaylist(id: $id)
      }
    `
    const result = await graphQL({ query, variables })
    return result
  }

  const unsubscribeFromPlaylist = async ({
    playlistId
  }: {
    playlistId: string
  }): Promise<any> => {
    const variables = {
      playlistId
    }
    const query = gql`
      mutation UnsubscribeFromPlaylist($playlistId: ID!) {
        unsubscribeFromPlaylist(playlistId: $playlistId)
      }
    `
    const result = await graphQL({ query, variables })
    return result
  }

  const updatePlaylist = async ({
    playlistId,
    name,
    description
  }: {
    playlistId: string
    name: string
    description: string | null
  }): Promise<any> => {
    const variables = {
      id: playlistId,
      data: {
        name,
        description
      }
    }
    const query = gql`
      mutation UpdatePlaylist($id: ID!, $data: UpdatePlaylistInput!) {
        updatePlaylist(id: $id, data: $data) {
          id
          name
          description
        }
      }
    `
    const result = await graphQL({ query, variables })
    return result
  }

  const reorderTracksOnPlaylist = async ({
    playlistId,
    order,
    moveBefore
  }: {
    playlistId: string
    order: number
    moveBefore: number
  }): Promise<any> => {
    const variables = {
      playlistId,
      reorder: [{ order, move_before: moveBefore }]
    }
    const query = gql`
      mutation ReorderTracksOnPlaylist(
        $playlistId: ID!
        $reorder: [TrackReorderInput!]!
      ) {
        reorderTracksOnPlaylist(playlistId: $playlistId, reorder: $reorder)
      }
    `
    const result = await graphQL({ query, variables })
    return result
  }

  const track = async ({ id }: { id: string }): Promise<any> => {
    const variables = {
      id
    }
    const query = gql`
      query Track($id: String!) {
        track(id: $id) {
          id
          file {
            name
            input
          }
          playlists {
            id
          }
          operations {
            id
            name
            status
            createdAt
            startedAt
            completedAt
            params
            result
          }
        }
      }
    `
    const result = await graphQL({ query, variables })
    return result
  }

  const fetchPlaylists = async ({
    sort,
    display
  }: {
    sort: TrackOrderSort
    display: TrackDisplaySet
  }): Promise<any> => {
    const variables = {
      sort,
      display
    }
    const query = gql`
      query Playlists($sort: Sort, $display: DisplaySet) {
        playlists(sort: $sort, display: $display) {
          id
          name
          description
          tracks(pagination: { limit: 999, offset: 0 }) {
            totalCount
          }
          isGlobal
          creator {
            id
            name
            avatar
          }
          guests {
            totalCount
            isFull
            node {
              id
              name
              avatar
            }
          }
          isShared
          invite
          createdAt
          notifications {
            unreadCount
          }
          expireAt
          type
          viewOnly
        }
      }
    `

    const result = await graphQL({ query, variables })
    return result?.playlists || null
  }

  const fetchPlaylist = async ({
    playlistId,
    field,
    sort
  }: {
    playlistId: string
    field?: TrackOrderField
    sort?: TrackOrderSort
  }): Promise<any> => {
    const variables = {
      id: playlistId,
      ...(field && field !== 'CUSTOM' && sort
        ? { sorting: { field, sort } }
        : {})
    }

    const query = gql`
      query Playlist($id: ID!, $sorting: TrackOrdering) {
        playlist(id: $id) {
          id
          type
          name
          description
          isGlobal
          isShared
          invite
          createdAt
          expireAt
          viewOnly
          creator {
            id
            name
            avatar
          }
          guests {
            totalCount
            node {
              id
              name
              avatar
            }
          }
          tracks(pagination: { limit: 999, offset: 0 }, sorting: $sorting) {
            totalCount
            edges {
              node {
                id
                isOwner
                isDemo
                category
                isShared
                owner
                file {
                  name
                  provider
                }
                summary {
                  v1 {
                    original {
                      artist
                      title
                      album
                      genre
                      format
                      size
                      audioChannelLayout
                      bpm
                      key
                      duration
                      sampleRate
                      bitRate
                      createdAt
                      comment
                      cover
                    }
                    separate {
                      status
                    }
                    beatschords {
                      status
                    }
                  }
                }
              }
            }
          }
          notifications {
            unreadCount
            messages {
              id
              content
              sentAt
              read
              payload
            }
          }
          custom {
            infoTitle
            infoUrl
            backgroundUrl
            backgroundColor
            logo
            logos
            video {
              title
              thumbnail
              video
              duration
              orientation
            }
          }
        }
      }
    `

    const result = await graphQL({ query, variables })
    return result.playlist || null
  }

  const sharePlaylist = async ({
    playlistId,
    enabled
  }: {
    playlistId: string
    enabled: boolean
  }): Promise<any> => {
    const variables = { id: playlistId, enabled }
    const query = gql`
      mutation SharePlaylist($id: ID!, $enabled: Boolean!) {
        sharePlaylist(id: $id, enabled: $enabled) {
          isShared
          invite
        }
      }
    `
    const result = await graphQL({ query, variables })
    return result?.sharePlaylist
  }

  const membersEditPlaylist = async ({
    id,
    viewOnly
  }: {
    id: string
    viewOnly: boolean
  }): Promise<any> => {
    const variables = { id, viewOnly }
    const query = gql`
      mutation UpdatePlaylistViewOnly($id: ID!, $viewOnly: Boolean!) {
        updatePlaylistViewOnly(id: $id, viewOnly: $viewOnly) {
          id
          viewOnly
        }
      }
    `
    const result = await graphQL({ query, variables })
    return result?.updatePlaylistViewOnly
  }

  const joinPlaylist = async ({
    playlistId
  }: {
    playlistId: string
  }): Promise<any> => {
    const variables = { id: playlistId }
    const query = gql`
      mutation JoinPlaylist($id: ID!) {
        joinPlaylist(id: $id)
      }
    `
    const result = await graphQL({ query, variables })
    return result?.joinPlaylist
  }

  const recentContacts = async ({
    playlistId
  }: {
    playlistId: string
  }): Promise<any> => {
    const variables = { playlistId }
    const query = gql`
      query RecentContacts($playlistId: ID) {
        recentContacts(playlistId: $playlistId) {
          id
          name
          avatar
          invited
        }
      }
    `
    const result = await graphQL({ query, variables })
    return result?.recentContacts
  }

  const sendPlaylistInvite = async ({
    playlistId,
    userId
  }: {
    playlistId: string
    userId: string
  }): Promise<any> => {
    const variables = { playlistId, userId }
    const query = gql`
      mutation SendPlaylistInvite($playlistId: ID!, $userId: String!) {
        sendPlaylistInvite(playlistId: $playlistId, userId: $userId)
      }
    `
    const result = await graphQL({ query, variables })
    return result
  }

  const removeGuestAccess = async ({
    playlistId,
    userId
  }: {
    playlistId: string
    userId: string
  }): Promise<any> => {
    const variables = { playlistId, guestId: userId }
    const query = gql`
      mutation RemoveGuestAccess($playlistId: ID!, $guestId: String!) {
        removeGuestAccess(playlistId: $playlistId, guestId: $guestId)
      }
    `
    const result = await graphQL({ query, variables })
    return result
  }

  return {
    track,
    fetchPlaylist,
    fetchPlaylists,
    createPlaylist,
    updatePlaylist,
    deletePlaylist,
    unsubscribeFromPlaylist,
    addTracksToPlaylist,
    removeTracksFromSetlist,
    reorderTracksOnPlaylist,
    sharePlaylist,
    joinPlaylist,
    membersEditPlaylist,
    recentContacts,
    sendPlaylistInvite,
    removeGuestAccess
  }
}

export default Playlist
