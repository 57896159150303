import { AppProps } from 'next/app'
import React, { ReactElement } from 'react'
import { RootContainer, ToastContainer } from '../containers'
import { ContextProvider } from '../context'
import { Shell } from '../../../../packages/web-app-shell/src'
import { I18nContainer } from '../../../../packages/web-app-shell/src/containers/i18n-container'
import '../styles/styles.scss'
import { AuthenticatedContainer } from '../containers/authenticated-container'

const isRemoveAuth = (pathname: string): boolean =>
  ['/_login-alt', '/_logout'].includes(pathname)

const MyApp = ({ Component, pageProps, router }: AppProps): ReactElement => {
  const includeTags = router.route !== '/playlist/invite/[token]'

  const notShowShell = isRemoveAuth(router?.pathname)

  if (notShowShell) {
    return (
      <I18nContainer>
        <Component {...pageProps} />
      </I18nContainer>
    )
  }

  return (
    <Shell>
      <ContextProvider>
        <I18nContainer>
          <RootContainer includeTags={includeTags}>
            <AuthenticatedContainer>
              <>
                <ToastContainer />
                <Component {...pageProps} />
              </>
            </AuthenticatedContainer>
          </RootContainer>
        </I18nContainer>
      </ContextProvider>
    </Shell>
  )
}

export default MyApp
