import React from 'react'
import { IconProps } from './icons.types'

export const InfoOutlined: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 15"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.41732 6.66671C7.14118 6.66671 6.91732 6.89057 6.91732 7.16671C6.91732 7.44285 7.14118 7.66671 7.41732 7.66671V6.66671ZM8.00065 7.16671H8.50065C8.50065 6.89057 8.27679 6.66671 8.00065 6.66671V7.16671ZM7.50065 11.1667C7.50065 11.4429 7.72451 11.6667 8.00065 11.6667C8.27679 11.6667 8.50065 11.4429 8.50065 11.1667H7.50065ZM14.1673 7.50004C14.1673 10.9058 11.4064 13.6667 8.00065 13.6667V14.6667C11.9587 14.6667 15.1673 11.4581 15.1673 7.50004H14.1673ZM8.00065 13.6667C4.5949 13.6667 1.83398 10.9058 1.83398 7.50004H0.833984C0.833984 11.4581 4.04261 14.6667 8.00065 14.6667V13.6667ZM1.83398 7.50004C1.83398 4.09428 4.5949 1.33337 8.00065 1.33337V0.333374C4.04261 0.333374 0.833984 3.542 0.833984 7.50004H1.83398ZM8.00065 1.33337C11.4064 1.33337 14.1673 4.09428 14.1673 7.50004H15.1673C15.1673 3.542 11.9587 0.333374 8.00065 0.333374V1.33337ZM7.41732 7.66671H8.00065V6.66671H7.41732V7.66671ZM7.50065 7.16671V11.1667H8.50065V7.16671H7.50065ZM7.91732 4.25004L7.91732 4.25004V5.25004C8.4696 5.25004 8.91732 4.80233 8.91732 4.25004H7.91732ZM7.91732 4.25004L7.91732 4.25004H6.91732C6.91732 4.80233 7.36503 5.25004 7.91732 5.25004V4.25004ZM7.91732 4.25004L7.91732 4.25004V3.25004C7.36503 3.25004 6.91732 3.69776 6.91732 4.25004H7.91732ZM7.91732 4.25004L7.91732 4.25004H8.91732C8.91732 3.69776 8.4696 3.25004 7.91732 3.25004V4.25004Z"
      fill="currentColor"
    />
  </svg>
)
